import update from 'immutability-helper';
import {getFormValues, isValid, submit, reducer as formReducer} from 'redux-form';

import {postDeliveryAddress} from 'web/helpers/basket_duck/api_client';
import {
  actions as navigationActions,
  reducer as navigationReducer,
} from 'web/helpers/navigation_duck';
import {
  reducer as signUpFormReducer,
  actions as signUpFormActions,
} from 'web/components/sign_up_form/duck';

export const UPDATING_ADDRESS = 'BASKET_UPDATE_DELIVERY_ADDRESS_BEGIN';
export const FINISH_UPDATING_ADDRESS = 'BASKET_UPDATE_DELIVERY_ADDRESS_FINISH';
export const ERROR_UPDATING_ADDRESS = 'BASKET_UPDATE_DELIVERY_ADDRESS_ERROR';
export const CONFIRM_ADDRESS = 'BASKET_UPDATE_DELIVERY_CONFIRM_ADDRESS';
export const DISMISS_CONFIRMATION_MODAL = 'BASKET_UPDATE_DELIVERY_ADDRESS_DISMISS_CONFIRMATION';

export const actions = {
  submitForm:
    ({addressIsConfirmed} = {}) =>
    (dispatch, getState) => {
      const state = getState();
      const {user} = state;
      const showSignUpForm = !state.user || !state.user.phone;

      // submit both forms to perform field-level validation
      if (showSignUpForm) {
        dispatch(submit('signUp'));
      }
      dispatch(submit('deliveryAddress'));

      // check if both signUp and deliveryAddress forms are valid
      const formIsValid =
        (!showSignUpForm || isValid('signUp')(state)) && isValid('deliveryAddress')(state);

      if (formIsValid) {
        // save user, then save delviery address, then redirect to review
        let promise;
        if (user && user.phone && user.lastName) {
          promise = Promise.resolve();
        } else if (user != null) {
          promise = dispatch(signUpFormActions.updateUser({rejectUponError: true}));
        } else {
          promise = dispatch(signUpFormActions.registerUser({rejectUponError: true}));
        }

        return promise
          .then(() => dispatch(actions.saveDeliveryAddress({addressIsConfirmed})))
          .then(() =>
            addressIsConfirmed
              ? dispatch(navigationActions.navigate('/basket'))
              : dispatch(navigationActions.navigate('/basket/review')),
          )
          .catch(() => null);
      }
    },
  cancelForm: () => (dispatch) => {
    const url = '/basket/review';
    dispatch(navigationActions.navigate(url));
  },
  closeConfirmationModal: () => (dispatch) => {
    dispatch({type: DISMISS_CONFIRMATION_MODAL});
  },
  saveDeliveryAddress:
    ({addressIsConfirmed} = {}) =>
    (dispatch, getState) => {
      dispatch({type: UPDATING_ADDRESS});
      const state = getState();

      return postDeliveryAddress(getFormValues('deliveryAddress')(state), addressIsConfirmed)
        .then((data) => dispatch({type: FINISH_UPDATING_ADDRESS, address: data}))
        .catch((error) => {
          if (error.type === 'PRODUCTS_UNAVAILABLE_IN_NEW_DELIVERY') {
            dispatch({type: CONFIRM_ADDRESS});
            return Promise.reject();
          }
          dispatch({type: ERROR_UPDATING_ADDRESS, error});
          return Promise.reject(error);
        });
    },
};

const initialState = {
  isSaving: false,
  error: null,
};
export function deliveryAddressPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATING_ADDRESS:
      return update(state, {isSaving: {$set: true}});

    case FINISH_UPDATING_ADDRESS:
      return update(state, {addressNeedsConfirmation: {$set: false}, isSaving: {$set: false}});

    case CONFIRM_ADDRESS:
      return update(state, {
        addressNeedsConfirmation: {$set: true},
        isSaving: {$set: false},
      });

    case DISMISS_CONFIRMATION_MODAL:
      return update(state, {
        addressNeedsConfirmation: {$set: false},
      });

    case ERROR_UPDATING_ADDRESS:
      return update(state, {
        addressNeedsConfirmation: {$set: false},
        isSaving: {$set: false},
        error: {$set: action.error},
      });
  }
  return state;
}

export function reducer(state, action) {
  return update(state, {
    form: {$set: formReducer(state.form, action)},
    deliveryAddressPage: {$set: deliveryAddressPageReducer(state.deliveryAddressPage, action)},
    navigation: {$set: navigationReducer(state.navigation, action)},
    signUpForm: {$set: signUpFormReducer(state.signUpForm, action)},
  });
}
