import {isEmpty} from 'lodash';
import {Helmet} from 'react-helmet-async';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {shape, string, bool, object, func} from 'prop-types';

import DeliveryAddressForm, {DeliveryAddressFormError} from 'web/components/delivery_address_form';
import SubmitButton from 'web/components/submit_button';
import MinimalLayout from 'web/components/minimal_layout';
import SignUpForm from 'web/components/sign_up_form';

import StepsHeader from '../components/steps_header';
import ConfirmAddressModal from '../components/confirm_address_modal';
import {actions, reducer} from './duck';

const missingUserInformationMessage = `We've noticed you're missing some delivery information we need. Please
complete this form in order to checkout properly.`;

export class DeliveryAddressPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableCTA: props.userNeedsUpdate,
      showMissingUserInformationMessage: props.userNeedsUpdate && props.deliveryDetails.address,
    };
    this.setCTA = this.setCTA.bind(this);
  }

  static reducer(state, action) {
    return reducer(state, action);
  }

  renderControls() {
    return (
      <div className="delivery-address-page__controls checkout__button-row">
        <SubmitButton
          className="button save button--checkout"
          isSaving={this.props.isSaving}
          onClick={this.props.onSave}
          disabled={this.state.disableCTA}
        >
          Save & Continue
        </SubmitButton>
        {this.props.hasDeliveryAddress ? (
          <button
            className="button button--checkout button-is-secondary cancel"
            onClick={this.props.onCancel}
            type="button"
          >
            Cancel
          </button>
        ) : null}
      </div>
    );
  }

  renderBackToBasket() {
    return (
      <div className="basket-page__back-link">
        <a className="back-to-basket checkout__link" href="/basket">
          <i className="icon icon-chevron left" />
          Return to basket
        </a>
      </div>
    );
  }

  setCTA(disableCTA) {
    this.setState({disableCTA});
  }

  render() {
    const noop = () => null;
    const {
      settings,
      error,
      signInUrl,
      showSignUpForm,
      user,
      userNeedsUpdate,
      addressNeedsConfirmation,
      deliveryDetails,
      isAttendedDeliveryRequired,
      onSave,
      onDismissConfirmation,
    } = this.props;

    const deliveryAddressInitialValues = {
      giftRecipientName: deliveryDetails.recipientName,
      giftRecipientPhone: deliveryDetails.phone,
      giftRecipientEmail: deliveryDetails.email,
      giftMessage: deliveryDetails.message,
      ...deliveryDetails,
    };

    return (
      <MinimalLayout>
        <Helmet>
          <title>Change Address | Good Eggs</title>
          {/* eslint-disable-next-line jsx-a11y/html-has-lang */}
          <html className="with-fixed-minimal-footer" />
          <body className="basket_delivery_page" />
          <script
            src={`https://maps.googleapis.com/maps/api/js?key=${settings.googleMapsKey}&libraries=places`}
          />
        </Helmet>

        <StepsHeader currentStep={1} />
        <div className="delivery-address-page narrow-page gutter">
          {error && <DeliveryAddressFormError error={error} />}

          <div className="checkout__header">
            <h1 className="checkout__title">Delivery Address</h1>
            {this.state.showMissingUserInformationMessage && (
              <h3 className="deliver-address--error">{missingUserInformationMessage}</h3>
            )}
            {user ? null : (
              <div className="checkout__title-returning">
                Returning?
                <a className="signin" href={signInUrl}>
                  {' '}
                  Sign in
                </a>
              </div>
            )}
          </div>
          {showSignUpForm && (
            <SignUpForm
              initialValues={user || {}}
              excludePassword
              onSubmit={noop}
              updateUser={userNeedsUpdate}
              setCTA={(setCTA) => {
                this.setCTA(setCTA);
              }}
              disableCTA={this.state.disableCTA}
              displayTerms={this.props.showSignUpTerms}
            />
          )}
          {addressNeedsConfirmation && (
            <ConfirmAddressModal
              onSave={() => onSave({addressIsConfirmed: true})}
              onCancel={() => onDismissConfirmation()}
            />
          )}
          <DeliveryAddressForm
            showGiftToggle
            initialValues={deliveryAddressInitialValues}
            isGift={deliveryDetails.isGift}
            isAttendedDeliveryRequired={isAttendedDeliveryRequired}
            onSubmit={noop}
          />
          <hr className="checkout__separator" />
          {this.renderControls()}
          {this.renderBackToBasket()}
        </div>
      </MinimalLayout>
    );
  }
}

DeliveryAddressPage.propTypes = {
  showSignUpForm: bool,
  showSignUpTerms: bool,
  deliveryDetails: object,
  error: object,
  user: object,
  signInUrl: string,
  hasDeliveryAddress: bool,
  isAttendedDeliveryRequired: bool,
  onCancel: func,
  isSaving: bool,
  onSave: func,
  onDismissConfirmation: func,
  settings: shape({
    googleMapsKey: string.isRequired,
  }).isRequired,
};

export function mapStateToProps(state) {
  return {
    deliveryDetails: state.deliveryDetails,
    isAttendedDeliveryRequired: state.isAttendedDeliveryRequired,
    user: state.user,
    signInUrl: state.signInUrl,
    hasDeliveryAddress: !isEmpty(state.deliveryDetails),
    showSignUpForm: !state.user || !state.user.phone || !state.user.lastName,
    showSignUpTerms: !state.user || !state.user.phone,
    userNeedsUpdate: state.user && (!state.user.phone || !state.user.lastName),
    isSaving: state.deliveryAddressPage.isSaving || state.signUpForm.isSaving,
    addressNeedsConfirmation: state.deliveryAddressPage.addressNeedsConfirmation,
    error: state.deliveryAddressPage.error || state.signUpForm.error,
    settings: state.settings,
    features: state.features,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    onSave: ({addressIsConfirmed} = {}) => dispatch(actions.submitForm({addressIsConfirmed})),
    onCancel: () => dispatch(actions.cancelForm()),
    onDismissConfirmation: () => dispatch(actions.closeConfirmationModal()),
  };
}

const ConnectedDeliveryAddressPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryAddressPage);

ConnectedDeliveryAddressPage.pageName = 'Change Address';

export default ConnectedDeliveryAddressPage;
